import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { saveAccount } from "@/api/account"; // 导入生成的 saveAccount 接口

export default defineComponent({
  name: "register",

  setup() {
    const form = ref({
      appLoginId: "",
      appLoginName: "",
      appLoginPwd: "",
      loginName: "" // 手机号码/登录名

    });
    const router = useRouter();
    const route = useRoute(); // 用于获取传入的参数

    const errors = ref({
      appLoginId: "",
      appLoginName: "",
      appLoginPwd: "",
      loginName: ""
    });
    const appCode = ref(""); // 保存传入的 appCode
    // 初始化时获取传入的 appCode

    onMounted(() => {
      appCode.value = route.query.appCode; // 从路由参数中获取 appCode
    });

    const onClickLeft = () => {
      router.back(); // 返回上一页
    }; // 验证表单


    const validateForm = () => {
      errors.value.appLoginId = form.value.appLoginId ? "" : "Login ID is required";
      errors.value.appLoginName = form.value.appLoginName ? "" : "Login name is required";
      errors.value.appLoginPwd = form.value.appLoginPwd ? form.value.appLoginPwd.length >= 6 ? "" : "Password must be at least 6 characters" : "Password is required";
      errors.value.loginName = form.value.loginName ? "" : "Phone (Login Name) is required";
      return !errors.value.appLoginId && !errors.value.appLoginName && !errors.value.appLoginPwd && !errors.value.loginName;
    }; // 提交表单


    const onSubmit = async () => {
      if (validateForm()) {
        try {
          const response = await saveAccount({
            appCode: appCode.value,
            appLoginId: form.value.appLoginId,
            appLoginName: form.value.appLoginName,
            appLoginPwd: form.value.appLoginPwd,
            loginName: form.value.loginName // 手机号码作为 loginName

          });

          if (response.code == 1) {
            _Toast.success("Account created successfully!"); // 创建成功提示


            setTimeout(() => {
              router.push("/accountList");
            }, 2000); // 2秒后跳转到添加账户页面
          } else {
            _Toast.fail(response.msg || "Unknown error"); // 创建失败提示

          }
        } catch (error) {
          console.error("创建账户失败", error);
          errors.value.loginName = "Network error, please try again";
        }
      } else {
        console.log("Validation failed, please check the form.");
      }
    };

    return {
      form,
      errors,
      onSubmit,
      onClickLeft
    };
  }

});